import {isRtlLanguage} from '@wix/wix-events-commons-statics'
import {TFunction} from '@wix/yoshi-flow-editor'
import {createAction} from '@reduxjs/toolkit'
import {callAPI, createActions} from '../../../../commons/actions/data-action-helper'
import {isResponsiveEditor} from '../../../../commons/selectors/environment'
import {WidgetComponentSettings} from '../../stylesParams'
import {createAsyncAction} from '../services/redux-toolkit'
import {GetState, State} from '../types/state'
import {convertFromNewToOldSettings} from '../utils/settings'
import {reloadEvents} from './events'

export const UPDATE_COMPONENT_DRAFT = createActions('UPDATE_COMPONENT_DRAFT')
export const UPDATE_COMPONENT = createActions('UPDATE_COMPONENT')
export const UPDATE_COMPONENT_TEXTS = 'UPDATE_COMPONENT_TEXTS'
export const INJECT_COMPONENT_DRAFT = 'INJECT_COMPONENT_DRAFT'

interface StyleParams {
  numbers: Record<string, number>
  booleans: Record<string, boolean>
}

export interface WidgetFillSettingsDefaultsProps {
  rtl: boolean
  responsive: boolean
  t: TFunction
}

export const initSettings = createAsyncAction<
  WidgetComponentSettings,
  {
    t: TFunction
    fillSettingsDefaults: (
      settings: WidgetComponentSettings,
      props: WidgetFillSettingsDefaultsProps,
    ) => WidgetComponentSettings
  }
>('FILL_SETTINGS_DEFAULTS', async ({t, fillSettingsDefaults}, {getState, extra: {serverApi, flowAPI}}) => {
  const state = getState()

  const {language} = serverApi
  const rtl = isRtlLanguage(language)
  const responsive = isResponsiveEditor(flowAPI.controllerConfig.config)

  const result = fillSettingsDefaults(state.component?.settings, {
    rtl,
    responsive,
    t,
  })

  return result
})

export const updateStyleParams = createAction<StyleParams>('UPDATE_STYLE_PARAMS')

export const updateStyles = createAsyncAction<void, StyleParams>(
  'UPDATE_STYLES',
  async (params, {dispatch, getState}) => {
    const layoutChanging = isLayoutChanging(getState(), params)
    await dispatch(updateStyleParams(params))
    if (layoutChanging) {
      dispatch(reloadEvents())
    }
  },
)

const isLayoutChanging = (state: State, params: any) => {
  const {listLayout, widgetLayout, recurringFilter} = state.component.settings
  const {listLayout: listLayoutNew, widgetLayout: widgetLayoutNew, recurringFilter: recurringFilterNew} = params.numbers

  const isParamChanging = (newValue, oldValue) => newValue !== undefined && oldValue !== newValue

  return (
    isParamChanging(listLayoutNew, listLayout) ||
    isParamChanging(widgetLayoutNew, widgetLayout) ||
    isParamChanging(recurringFilterNew, recurringFilter)
  )
}

export const updateComponentDraft =
  (component: wix.events.editor.WebComponentConfig, reload = true) =>
  async (dispatch: Function, getState: GetState) => {
    const {membersEnabled, paidPlansEnabled} = getState()
    await dispatch(callAPI(UPDATE_COMPONENT_DRAFT, component, membersEnabled, paidPlansEnabled))
    if (reload) {
      await dispatch(reloadEvents())
    }
  }

export const updateComponentTexts = (payload: object) => ({type: UPDATE_COMPONENT_TEXTS, payload})

export const updateComponent = () => (dispatch: Function, getState: GetState) => {
  const state = getState()
  const {byStatus, byEventId, byCategoryId} = state.component
  const settings = convertFromNewToOldSettings(state.component.settings, Object.keys(state.component.params || {}))

  dispatch(callAPI(UPDATE_COMPONENT, {settings, byStatus, byEventId, byCategoryId}))
}
